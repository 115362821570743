/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/mortalone/opencart@3.0.x.x_Maintenance/upload/catalog/view/javascript/jquery/jquery-2.1.1.min.js
 * - /gh/mortalone/opencart@3.0.x.x_Maintenance/upload/catalog/view/javascript/bootstrap/js/bootstrap.min.js
 * - /gh/mortalone/opencart@3.0.x.x_Maintenance/upload/catalog/view/javascript/jquery/swiper/js/swiper.jquery.min.js
 * - /gh/mortalone/opencart@3.0.x.x_Maintenance/upload/catalog/view/javascript/common.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
